/* You can add global styles to this file, and also import other style files */
@import "../node_modules/flag-icon-css/css/flag-icon.min.css";

body {
    min-height: 100vh;
    margin: 0;
    position: relative;
    padding-bottom: 100px; /* height of the footer */
    box-sizing: border-box;
}

.row {
    margin-top: 15px;
    margin-bottom: 15px;
}

a {
    cursor: pointer;
}

.btn-mucci {
    display: inline-block;
    font-weight: 400;
    color: white;
    text-align: center;
    vertical-align: middle;
    -webkit-user-select: none;
    user-select: none;
    background-color: transparent;
    border: 1px solid transparent;
    padding: .375rem .75rem;
    font-size: 1rem;
    line-height: 1.5;
    border-radius: .25rem;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    background-color: #673334;
    border-color: #673334;
}

.btn-mucci:focus {
    outline: none;
    box-shadow: 0 0 3pt 2pt #6733346b;
}

.btn-mucci:disabled {
    opacity: 50%;
}

.custom-control-input-mucci:focus ~ .custom-control-label::before {
    border-color: #673334 !important;
    box-shadow: 0 0 0 0.2rem rgba(167, 40, 40, 0.25) !important;
}

.custom-control-input-mucci:checked ~ .custom-control-label::before {
    border-color: #673334 !important;
    background-color: #673334 !important;
}

.custom-control-input-mucci:focus:not(:checked) ~ .custom-control-label::before {
    border-color: #673334 !important;
}

.custom-control-input-mucci:not(:disabled):active ~ .custom-control-label::before {
    background-color: #6e6767 !important;
    border-color: #6e6767 !important;
}

.radio-item {
    display: inline-block;
    position: relative;
    padding: 0 6px;
    margin: 10px 0 0;
}

.radio-item input[type='radio'] {
   display: none;
}

.radio-item label {
    color: #666;
    font-weight: normal;
}

.radio-item label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 5px;
    margin: 0 10px 0 0;
    width: 20px;
    height: 20px;
    border-radius: 11px;
    border: 2px solid #673334;
    background-color: transparent;
}

.radio-item input[type=radio]:checked + label:after {
    border-radius: 11px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 9px;
    left: 10px;
    content: " ";
    display: block;
    background: #673334;
}


  .slider {
    -webkit-appearance: none;
    height: 10px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;
  }

  .slider:hover {
    opacity: 1;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #673334;
    cursor: pointer;
  }

  .slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #673334;
    cursor: pointer;
  }

.dropdown-mucci.active {
    background-color: #673334 !important;
}

.dropdown-mucci:active {
    background-color: #673334 !important;
}

.thead-mucci {
    background-color: #673334 !important;
    color: white !important;
}

.pagination > li > a {
    background-color: white;
    color: #673334;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
    z-index: 3;
    color: #673334;
    background-color: #eee;
    border-color: #ddd;
}

.pagination > .active > a {
    color: white;
    background-color: #673334 !important;
    border: solid 1px #673334 !important;
}

.pagination > .active > a:hover {
    background-color: #673334 !important;
    border: solid 1px #673334;
}

.loading {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.loading-row {
    background-color: white;
}

span.selected-item {
    background: #673334 !important;
    border: #673334 !important;
}

.multiselect-item-checkbox input[type=checkbox] + div:before {
    border: 2px solid #673334 !important;
    color: #673334 !important;
}

.multiselect-item-checkbox input[type=checkbox]:checked + div:before {
    background: #673334 !important;
    color: #673334 !important;
    border: 2px solid #673334 !important;
}

/*#login-page {
    background-image: url("assets/vasi.jpeg");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: #673334;
}*/

.overflow-scroll{
    max-height: 148px;
    overflow-y: auto;
  }

.modal-content{
    animation-name: example;
    animation-duration: 0.3s;
}

.wrapper { overflow-x: auto; }
.wrapper table { white-space: nowrap }

@keyframes example {
    0%   {transform: scale(0.5)}
    75%  {transform: scale(1.1)}
    100% {transform: scale(1)}
}
