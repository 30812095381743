@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/variables";
@import "../node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/bootstrap";

$mucci-color: #673334;

.page-item.active .page-link {
    background-color: $mucci-color;
    border-color: $mucci-color;
}

.page-link {
    color: $mucci-color;
}

thead th {
    cursor: pointer;
    margin-right: 15px;
    margin-left: 15px;

    span {
      visibility: hidden;
    }

    &.active {

      span {
        visibility: visible;
      }
    }
  }
